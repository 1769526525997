
import { defineComponent, ref, watch } from "vue";
import { apiEndpoint } from "@/mixin/apiMixin";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "EventShowModal",
  props: {
    data: { type: Object },
  },
  setup(props) {
    const eventInfo = ref({});

    const downloadFile = (file_path,file_name) => {
      const anchor = document.createElement("a");
      anchor.href = apiEndpoint.data().BEE_FILE_URL + file_path;
      anchor.target = '_blank';
      anchor.download = file_name;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    };

    watch(
      () => props.data,
      (newData) => {
        if (newData) {
          eventInfo.value = {
            ...eventInfo.value,
            ...newData,
          };
        }
      }
    );

    return { eventInfo,downloadFile,apiEndpoint };
  },
});
