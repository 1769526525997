
import { defineComponent, ref, watch, onMounted } from "vue";
import { number, object, string } from "yup";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { VueCookieNext } from "vue-cookie-next";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "EventImportModal",
  props: {
    data: { type: Object },
    loadData: { type: Function },
  },
  setup(props) {
    const { bus } = useBus();
    const submitButton = ref<HTMLElement | null>(null);
    const formData = ref({
      id: "",
      updated_by: "",
      ia_partner_id: VueCookieNext.getCookie("_ia_partner_id") != 'null'
          ? VueCookieNext.getCookie("_ia_partner_id")
          : 0,
      created_by: VueCookieNext.getCookie("_user_id"),
      event_import_file: "",
    });

    watch(
      () => props.data,
      (newData) => {
        if (newData) {
          formData.value = {
            ...formData.value,
            ...newData,
          };
        }
      }
    );

    const uploadFile = (event) => {
      formData.value.event_import_file = event.target.files[0];
    };

    const schema = object().shape({
      event_import_file: string().required("file is required"),
    });

    const errors = ref({});

    const save = async () => {
      try {
        submitButton.value?.setAttribute('data-kt-indicator', 'on');
        const inputFile = document.getElementById('event_import_file') as HTMLInputElement;
        if (inputFile) {
          inputFile.value = '';
        }

        // await schema.validate(formData.value, { abortEarly: false });
        ApiService.setHeaderforImage();
        const formDataObj = new FormData();
        formDataObj.append(
          "event_import_file",
          formData.value.event_import_file
        );
        formDataObj.append("ia_partner_id", formData.value.ia_partner_id);
        formDataObj.append("created_by", formData.value.created_by);

        ApiService.post(
          apiEndpoint.data().VUE_APP_IMPORT_EVENT_LIST,
          formDataObj
        )
          .then((response) => {
            submitButton.value?.removeAttribute('data-kt-indicator');
            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              DrawerComponent.hideAll();
              bus.emit("loadData", true);
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.msg,
                type: "error",
              });
            }
          })
          .catch(({ response }) => {
            console.log(response);
          });

        formData.value = {
          id: "",
          updated_by: "",
          ia_partner_id: VueCookieNext.getCookie("_ia_partner_id") != 'null'
              ? VueCookieNext.getCookie("_ia_partner_id")
              : 0,
          created_by: VueCookieNext.getCookie("_user_id"),
          event_import_file: "",
        };
        errors.value = {};
      } catch (validationErrors) {
        errors.value = validationErrors.inner.reduce((acc, error) => {
          acc[error.path] = error.message;
          return acc;
        }, {});
      }
    };

    return { formData, errors, save, uploadFile, submitButton };
  },
});
