
import { defineComponent, ref, onMounted } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import EventImportModal from "@/components/modals/forms/event/EventImportModal.vue";
import EventShowModal from "@/components/modals/forms/event/EventShowModal.vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";
import { formatDate } from '@/core/helpers/genericHelper.ts';
import { useRouter } from "vue-router";
import { VueCookieNext } from "vue-cookie-next";
export default defineComponent({
  name: "EventList",
  components: {
    EventImportModal,
    EventShowModal,
  },
  setup() {
    const router = useRouter();
    const { bus } = useBus();
    const editData = ref(null);
    const showData = ref(null);
    const eventList = ref([]);
    const upazilaOptions = ref([]);
    const componentOptions = ref([]);
    const iaPartnerOptions = ref([]);
    const divisionOptions = ref([]);
    const districtOptions = ref([]);
    const selectedPartner = ref(null);
    const selectedComponent = ref(null);
    const selectedDivision = ref(null);
    const selectedDistrict = ref(null);
    const selectedUpazila = ref(null);
    const selectedInstitute = ref(null);
    const currentPage = ref(1);
    const perPageOptions = [10, 20, 50];
    const selectedPerPage = ref(perPageOptions[0]);
    const totalRows = ref(0);
    const exportType = ref(null);

    const handleSizeChange = (val) => {
      selectedPerPage.value = val;
      loadData();
    };

    const handleCurrentChange = (val) => {
      currentPage.value = val;
      loadData();
    };

    const getIaPartnerOptions = async () => {
      try {

        const id = VueCookieNext.getCookie("_ia_partner_id") !== "null"
            ? VueCookieNext.getCookie("_ia_partner_id")
            : 0;

        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_IAPARTNER_LIST,
            {
              id : id
            }
        );

        iaPartnerOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));

      } catch (error) {
        console.error("Error fetching parent output options:", error);
      }
    };

    const getComponent = async () => {
      try {
        const response = await ApiService.get(
          apiEndpoint.data().VUE_APP_COMPONENT_LIST
        );

        componentOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDivision = async () => {
      try {
        const response = await ApiService.get(
          apiEndpoint.data().VUE_APP_DIVISION_LIST
        );

        divisionOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.division_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDivisionWistDistrict = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_DISTRICT_LIST,
          {
            geo_division_id: selectedDivision.value,
          }
        );

        districtOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.district_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDistrictWistUpazila = async () => {
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_UPAZILA_LIST,
            {
              geo_district_id: selectedDistrict.value,
            }
        );
        upazilaOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.upazila_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const create = (data) => {
      editData.value = data ? data : null;
    };

    const edit = (id) => {
      router.push({ name: 'save-event', params: { id: id } });
    };

    const show = (data) => {
      showData.value = data ? data : null;
    };

    const deleteEvent = async (id) => {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const response = await ApiService.post(
              apiEndpoint.data().VUE_APP_EVENT_DELETE,
              {
                id: id,
              }
            );

            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              loadData();
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.message,
                type: "error",
              });
            }
          } catch (error) {
            console.error("Error deleting output:", error);
            ElNotification({
              dangerouslyUseHTMLString: true,
              message: "Error deleting output",
              type: "error",
            });
          }
        }
      });
    };

    const downloadImportTemplate = (file_path,type) => {
      const anchor = document.createElement("a");
      anchor.href = apiEndpoint.data().BEE_FILE_URL + file_path;
      anchor.target = '_blank'; // Open link in the same window
      anchor.download = 'event_template.xlsx';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    };

    const loadData = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_EVENT_LIST,
          {
            ia_partner_id: VueCookieNext.getCookie("_ia_partner_id") != 'null'
                ? VueCookieNext.getCookie("_ia_partner_id")
                : selectedPartner.value,
            component_id: selectedComponent.value,
            geo_division_id: selectedDivision.value,
            geo_district_id: selectedDistrict.value,
            event_institute: selectedInstitute.value,
            page: currentPage.value,
            per_page: selectedPerPage.value,
          }
        );
        eventList.value = response.data.data.data;
        totalRows.value = response.data.data.total;

        console.log(eventList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const exportChange = async () => {
      if (exportType.value == "excel") {
        exportExcel();
      }
    };

    const exportExcel = async () => {
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_EXPORT_EVENT_LIST,
            {}
        );

        if (response.data.status === "success" && response.data.data) {
          // Create a link element
          const downloadLink = document.createElement("a");
          downloadLink.href = apiEndpoint.data().BEE_FILE_URL+response.data.data;
          console.log(downloadLink.href);
          downloadLink.target = "_blank"; // Open in a new tab/window
          downloadLink.download = "event_list.xlsx"; // Specify the desired file name

          // Append the link to the document
          document.body.appendChild(downloadLink);

          // Trigger a click event on the link to start the download
          downloadLink.click();

          // Remove the link from the document
          document.body.removeChild(downloadLink);
        } else {
          console.error("Invalid response for export:", response);
        }
      } catch (error) {
        console.error("Error exporting Excel:", error);
      }
    };

    onMounted(async () => {
      DrawerComponent.bootstrap();
      DrawerComponent.updateAll();

      getIaPartnerOptions();
      loadData();

      bus.on("loadData", async () => {
        loadData();
      });

      getComponent();
      getDivision();
    });

    return {
      editData,
      showData,
      eventList,
      create,
      edit,
      show,
      deleteEvent,
      componentOptions,
      divisionOptions,
      districtOptions,
      upazilaOptions,
      getDivisionWistDistrict,
      loadData,
      currentPage,
      perPageOptions,
      selectedPerPage,
      totalRows,
      handleSizeChange,
      handleCurrentChange,
      formatDate,
      downloadImportTemplate,
      exportChange,
      exportType,
      iaPartnerOptions,
      selectedComponent,
      selectedDivision,
      selectedDistrict,
      selectedUpazila,
      selectedInstitute,
      selectedPartner,
      getDistrictWistUpazila,
    };
  },
});
